import React, { Component } from 'react';
import Phone from '../../../../assets/images/phone.png';
import Map from '../../../../assets/images/map.png';
import Pen from '../../../../assets/images/pen.png';
import CredoModal from '../../../../components/CredoModal';
import { rupeeDecimalFormatter } from '../../../../utils/utility';
import Reject from '../../../../assets/images/reject.png';
import Cross from '../../../../assets/images/crossnew.png';
import Green from '../../../../assets/images/Greentik.png';
import Question from '../../../../assets/images/QuestionIcon.svg';
import Group from '../../../../assets/images/Group.png';
import Tick from '../../../../assets/images/tick.png';
import Compare from '../../../../assets/images/compare.png';
import File from '../../../../assets/images/Frame.png';
import { POST } from '../../../../utils/webAPI.service';
import { Helmet } from 'react-helmet';
import { getDigilockerDetails } from './application.actions';
import DetailButton from '../../../../assets/images/detailicon.png';
import AddBankDetails from './BankDetailUpdateModal';
import {
  API_POST_NOTES,
  API_POST_CREATE_OFFER,
  API_POST_REJECT_OFFER,
  API_POST_SMS_EXCEL,
} from '../../../../utils/APIUrls';
import CompareNewModal from './CompareNewModal';
import CompareModal from './CompareModal';
import ImagesModal from './ImagesModal';
import LocationModal from './LocationModal';
import UserProfileModal from './UserProfileModal';
import EntityKyc from './EntityKycModal';
import KycModal from './KycModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PennyDropResponseModal from './PennyDropModal';
import {
  getBusinessDetails,
  getAddressDetails,
  getCompareDetails,
  getConditionNotes,
  getCommentNotes,
  getBankDetails,
  getPhotos,
  getSubProgramList,
  getProgramList,
  getCpoDocument,
  getSupplierDetails,
} from './application.actions';
import { getActiveLoan } from '../../Offer/offer.actions';
import { loaderModal } from '../../../../store/actions';
import DedupeModal from './DedupeModal';
import DedupeTick from '../../../../assets/images/DedupeTick.png';
import CrossIcon from '../../../../assets/images/DedupeCross.png';
import Excel from '../../../../assets/images/SmsExcel.svg';
import SubProgramModal from './SubProgramModal';
import kyc from './Modal';
import { RejectCases } from './RejectModal';

class OverView extends Component {
  state = {
    isEditable: false,
    isConditionEditable: false,
    comment: '',
    condition: '',
    modalOpen: false,
    activeFilter: 'APPLICATION',
    modalType: CompareModal,
    activeBank: 0,
  };

  componentDidMount = async () => {
    let { actions, match } = this.props;
    try {
      let { loanId, userId } = match.params,
        {
          getBusinessDetails,
          getAddressDetails,
          getCompareDetails,
          getConditionNotes,
          getCommentNotes,
          getBankDetails,
          getPhotos,
          getProgramList,
          getDigilockerDetails,
          getCpoDocument,
          getSupplierDetails,
        } = actions,
        token = localStorage.getItem('token');
      actions.loaderModal(true);
      if (token) {
        await getPhotos(loanId);
        await getBusinessDetails(loanId, userId);
        getAddressDetails(loanId);
        getCompareDetails(loanId);
        getConditionNotes(loanId);
        getCommentNotes(loanId);
        getBankDetails(userId);
        getProgramList(this.props?.promoterData?.supplierId);
        getDigilockerDetails(loanId);
        getCpoDocument(loanId);
        getSupplierDetails(loanId);
        this.setState({ offerLimitAmt: this.props.offerLimitAmt });
      } else {
        let { history } = this.props;
        alert('Unauthorized access');
        history.push('/');
      }
    } catch (error) {
      actions.loaderModal(false);
      console.error('error:', error);
    } finally {
      actions.loaderModal(false);
    }
  };

  inputHandler = (e, type) => {
    if (type === 'isEditable') {
      document.getElementById('comment').focus();
      this.setState({ isEditable: true });
    } else if (type === 'isConditionEditable') {
      document.getElementById('condition').focus();
      this.setState({ isConditionEditable: true });
    }
  };

  toggleModal = (name) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
    }));
  };

  saveCommentsHandler = (e, type) => {
    const { match } = this.props,
      { loanId } = match.params;

    if (type === 'comment' && (this.state.comment !== '' || null)) {
      let data = {
        comment: this.state.comment,
        serviceRequestBy: null,
        uniqueId: loanId,
        type,
      };
      POST(API_POST_NOTES, data)
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({ isEditable: false });
          } else if (data.code === 401) {
            alert(data?.message);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 406) {
            alert(err?.response?.data?.errors[0]);
          }
          throw err;
        });
    } else if (type === 'comment' && (this.state.comment === '' || null)) {
      this.setState({ isEditable: false });
    }

    if (type === 'condition' && (this.state.condition !== '' || null)) {
      let data = {
        comment: this.state.condition,
        serviceRequestBy: null,
        uniqueId: loanId,
        type,
      };
      POST(API_POST_NOTES, data)
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({ isConditionEditable: false });
          } else if (data.code === 401) {
            alert(data?.message);
          }
        })
        .catch((err) => {
          throw err;
        });
    } else if (type === 'condition' && (this.state.condition === '' || null)) {
      this.setState({ isConditionEditable: false });
    }
  };

  programHandler = async (e) => {
    let { name, value } = e.target,
      { actions, promoterData } = this.props,
      supplierIdValue = promoterData?.supplierId,
      { loaderModal, getSubProgramList } = actions;
    await this.setState({ [name]: value });
    if (name === 'programName') {
      loaderModal(true);
      await getSubProgramList(supplierIdValue, this.state.programName);
      loaderModal(false);
    }
  };
  approveLimitHandler = async (e) => {
    let { match, actions } = this.props,
      { loanId } = match.params,
      { offerLimitAmt, programName, subProgramName } = this.state,
      dataToSend = { loanId, offerLimitAmt, programName, subProgramName };
    actions.loaderModal(true);

    POST(API_POST_CREATE_OFFER, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          let { history, match } = this.props,
            { loanId, userId } = match.params;
          history.push(`/pay-app/${loanId}/${userId}/offer`);
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        if (err?.response?.status === 406) {
          alert(err?.response?.data?.errors[0]);
        } else if (err?.response?.status === 500) {
          alert(err?.response?.data?.message);
        } else {
          alert('Something went wrong');
        }
        throw err;
      });
  };

  smsExcelDownload = () => {
    let { match, promoterData, actions } = this.props,
      { mobileNumber } = promoterData,
      { loanId } = match.params,
      dataToSend = {
        docType: 'RAW_MESSAGE',
        loanId,
        mobileNumber,
      };
    actions.loaderModal(true);
    POST(API_POST_SMS_EXCEL, dataToSend)
      .then(({ data }) => {
        actions.loaderModal(false);
        if (data.code === 200) {
          const downloadLink = data.data;
          const link = document.createElement('a');
          link.href = `${downloadLink}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  rejectHandler = () => {
    let { match } = this.props,
      { loanId } = match.params;
    POST(API_POST_REJECT_OFFER(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Case successfully Rejected');
          this.toggleModal('');
          this.setState({ offerLimitAmt: null });
        } else if (data.code === 999 || data.code === 401) {
          alert(data?.message);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  PanNameIconVerification = (verifyIcon) => {
    if (verifyIcon === true) {
      return Green;
    } else if (verifyIcon === false) {
      return Cross;
    } else {
      return Question;
    }
  };

  render() {
    let { isEditable, isConditionEditable, modalOpen, modalType, activeBank } =
        this.state,
      {
        promoterData,
        address,
        bankDetails,
        deviceDetails,
        comment,
        condition,
        photos,
        programList,
        transactionDetails,
        dedupeDetails,
        subProgramList,
        supplierDetails,
      } = this.props,
      { profileDocument = [], businessDocuments = [] } = photos,
      { business, current, permanent } = address,
      { accounts = [] } = transactionDetails;
    let { isNameMatched, isPanVerified, coApplicantList } = promoterData;
    let {
      supplierName,
      supplierOverallLimit,
      supplierAvailableLimit,
      supplierId,
      utilisedAmt,
      buyerCount,
      overdueAmt,
    } = supplierDetails || {};

    return (
      <div className=' container'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Application</title>
        </Helmet>
        <div className='application-wrapper'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='entity_container container-box '>
                <div className='row m-10'>
                  <div className='col-md-9  entity_container_heading'>
                    <div className='mb-2'>Legal Name</div>
                    <div className=' entity_container_title'>
                      {promoterData?.legalName || '-'}
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div
                      className=' kyc_details promoters_image_documents promoters_image_details center'
                      onClick={() => this.toggleModal(EntityKyc)}
                    >
                      Entity Kyc
                    </div>
                  </div>
                </div>

                <div className='row m-20'>
                  <div className='col-md-4 '>
                    <div className='entity_container_heading'>GST</div>
                    <div className='entity_container_value'>
                      {promoterData?.gstin || '-'}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>GST Status</div>
                    <div className='entity_container_value'>
                      {' '}
                      {promoterData?.gstStatus || '-'}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>
                      PAN of Entity
                    </div>
                    <div className='entity_container_value'>
                      {promoterData?.entityPan || '-'}
                    </div>
                  </div>
                </div>
                <div className='row m-20'>
                  <div className='col-md-4 '>
                    <div className='entity_container_heading'>State</div>
                    <div className='entity_container_value'>
                      {promoterData?.state || '-'}
                    </div>
                  </div>
                  <div className='col-md-4 '>
                    <div className='entity_container_heading'>City Name</div>
                    <div className='entity_container_value'>
                      {promoterData?.cityName || '-'}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>Constitution</div>
                    <div className='entity_container_value'>
                      {promoterData?.constitution || '-'}
                    </div>
                  </div>
                </div>
                <div className='row m-20'>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>
                      Commencement year
                    </div>
                    <div className='entity_container_value'>
                      {promoterData?.commencementYear || '-'}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>Vintage</div>
                    <div className='entity_container_value'>
                      {promoterData?.vintage || '-'}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>
                      Signup Timestamp
                    </div>
                    <div className='entity_container_value'>
                      {promoterData?.signupTime || '-'}
                    </div>
                  </div>
                </div>
                <div className='row m-20'>
                  <div className='col-md-4'>
                    <div className='entity_container_heading'>
                      Business Type
                    </div>
                    <div className='entity_container_value'>
                      {promoterData?.businessType || '-'}
                    </div>
                  </div>
                </div>

                <div className='entity_container container-box entity_height grey'>
                  <div className='row m-10'>
                    <div className='col-md-12 entity_container_heading'>
                      Addresses
                    </div>
                  </div>
                  <div className='row m-10'>
                    <div className='col-md-12 entity_container_title'>
                      Business Premises{' '}
                    </div>
                  </div>
                  <div className='row m-20'>
                    <div className='col-md-4'>
                      <div className='entity_container_bold'>
                        Business Residence
                      </div>
                      <div className='entity_container_sub-value'>
                        {business?.addr1} {business?.addr2}{' '}
                        {business?.streetOrLandmark} {business?.city}{' '}
                        {business?.state} {business?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row container-box m-20'>
                <div className='col-md-8'>
                  <div className='row promotors-container'>
                    <div className='col-md-3'>
                      <img
                        onClick={() => this.toggleModal(UserProfileModal)}
                        src={profileDocument?.fileUrl}
                        alt='user profile'
                        className='human_img'
                      />
                    </div>
                    <div className='col-md-9'>
                      <div className='row m-10'>
                        <div className='col-md-12 entity_container_heading'>
                          Promoter Details
                        </div>
                      </div>
                      <div className='row m-10'>
                        <div className='col-md-12 entity_container_title'>
                          {promoterData?.fullName || '-'}{' '}
                          <img
                            alt='verificationIcon'
                            className='icon'
                            src={this.PanNameIconVerification(isNameMatched)}
                          />
                        </div>
                      </div>
                      <div className='row m-10 '>
                        <div className='col-md-6'>
                          <div className='entity_container_heading'>Gender</div>
                          <div className='entity_container_value'>
                            {promoterData?.gender?.toUpperCase() || '-'}{' '}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='entity_container_heading'>DOB</div>
                          <div className='entity_container_value'>
                            {promoterData?.dob || '-'}
                          </div>
                        </div>
                      </div>

                      <div className='row m-10 '>
                        <div className='col-md-6'>
                          <div className='entity_container_heading'>
                            Aadhaar Number
                          </div>
                          <div className='entity_container_value'>
                            {promoterData?.aadhar || '-'}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='entity_container_heading'>PAN</div>
                          <div className='entity_container_value'>
                            {promoterData?.pan || '-'}
                            <img
                              alt='verificationIcon'
                              className='icon'
                              src={this.PanNameIconVerification(isPanVerified)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 promoters_image'>
                  <div className=''>
                    <div className=' entity_container_heading m-10'>
                      Documents
                    </div>
                    <div
                      className=' kyc_details promoters_image_documents promoters_image_verify_details'
                      onClick={() => this.toggleModal(kyc)}
                    >
                      <img
                        src={File}
                        alt='compare logo'
                        className='compare_img'
                      />
                      KYC Documents
                    </div>
                    <div
                      className='promoters_image_documents  promoters_image_details'
                      onClick={() => this.toggleModal(CompareNewModal)}
                    >
                      {' '}
                      <img
                        src={Compare}
                        alt='compare logo'
                        className='compare_img'
                      />
                      Compare details
                    </div>
                    {dedupeDetails?.overAllDedupeStatus ? (
                      <div
                        className='promoters_image_documents promoters_image_dedupe_failed'
                        onClick={() => this.toggleModal(DedupeModal)}
                      >
                        <img
                          src={CrossIcon}
                          alt='compare logo'
                          className='compare_img'
                        />
                        Dedupe Failed
                      </div>
                    ) : (
                      <div
                        className='promoters_image_documents promoters_image_dedupe_success'
                        onClick={() => this.toggleModal(DedupeModal)}
                      >
                        <img
                          src={DedupeTick}
                          alt='compare logo'
                          className='compare_img'
                        />
                        Dedupe Pass
                      </div>
                    )}
                  </div>
                </div>

                <div className='entity_container container-box entity_height grey'>
                  <div className='row m-10'>
                    <div className='col-md-12 entity_container_heading'>
                      Addresses
                    </div>
                  </div>
                  <div className='row m-10'>
                    <div className='col-md-12 entity_container_title'>
                      Residence Premises{' '}
                    </div>
                  </div>
                  <div className='row m-20'>
                    <div className='col-md-4'>
                      <div className='entity_container_bold'>
                        Permanent Residence
                      </div>
                      <div className='entity_container_sub-value'>
                        {permanent?.addr1} {permanent?.addr2}{' '}
                        {permanent?.streetOrLandmark} {permanent?.city}{' '}
                        {permanent?.state} {permanent?.pincode}
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='entity_container_bold'>
                        Current Residence
                      </div>
                      <div className='entity_container_sub-value'>
                        {current?.addr1} {current?.addr2}{' '}
                        {current?.streetOrLandmark} {current?.city}{' '}
                        {current?.state} {current?.pincode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {coApplicantList?.map((element, index) => {
                return (
                  <div className='row container-box m-20' key={index}>
                    <div className='col-md-8'>
                      <div className='row promotors-container'>
                        <div className='col-md-3'>
                          <img
                            // onClick={() => this.toggleModal(UserProfileModal)}
                            // src={profileDocument?.fileUrl}
                            alt='user profile'
                            className='human_img'
                          />
                        </div>
                        <div className='col-md-9'>
                          <div className='row m-10'>
                            <div className='col-md-12 entity_container_heading'>
                              Coapplicant Details
                            </div>
                          </div>
                          <div className='row m-10'>
                            <div className='col-md-12 entity_container_title'>
                              {element?.coAppName || '-'}{' '}
                            </div>
                          </div>
                          <div className='row m-10 '>
                            <div className='col-md-6'>
                              <div className='entity_container_heading'>
                                Gender
                              </div>
                              <div className='entity_container_value'>
                                {element?.gender || '-'}{' '}
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='entity_container_heading'>
                                DoB
                              </div>
                              <div className='entity_container_value'>
                                {element?.dob || '-'}
                              </div>
                            </div>
                          </div>

                          <div className='row m-10 '>
                            <div className='col-md-6'>
                              <div className='entity_container_heading'>
                                Aadhaar Number
                              </div>
                              <div className='entity_container_value'>
                                {element?.coAppAadhaarNumber || '-'}
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='entity_container_heading'>
                                PAN
                              </div>
                              <div className='entity_container_value'>
                                {element?.coAppPanNumber || '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 promoters_image'>
                      <div className=''>
                        <div className=' entity_container_heading m-10'>
                          Documents
                        </div>
                        <div
                          className=' kyc_details promoters_image_documents promoters_image_verify_details'
                          // onClick={() => this.toggleModal(kyc)}
                        >
                          <img
                            src={File}
                            alt='compare logo'
                            className='compare_img'
                          />
                          KYC Documents
                        </div>
                        <div
                          className='promoters_image_documents  promoters_image_details'
                          onClick={() => this.toggleModal(CompareNewModal)}
                        >
                          {' '}
                          <img
                            src={Compare}
                            alt='compare logo'
                            className='compare_img'
                          />
                          Compare details
                        </div>
                      </div>
                    </div>

                    <div className='entity_container container-box entity_height grey'>
                      <div className='row m-10'>
                        <div className='col-md-12 entity_container_heading'>
                          Addresses
                        </div>
                      </div>
                      <div className='row m-10'>
                        <div className='col-md-12 entity_container_title'>
                          Residence Premises{' '}
                        </div>
                      </div>
                      <div className='row m-20'>
                        <div className='col-md-4'>
                          <div className='entity_container_bold'>
                            Permanent Residence
                          </div>
                          <div className='entity_container_sub-value'>NA</div>
                        </div>

                        <div className='col-md-4'>
                          <div className='entity_container_bold'>
                            Current Residence
                          </div>
                          <div className='entity_container_sub-value'>NA</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className='col-md-4 pl-0'>
              <div className='row m-0'>
                <div className='col-md-12 p-0'>
                  <div
                    className='limit container-box'
                    style={{ backgroundImage: 'url(' + Group + ')' }}
                  >
                    <div className='limit_container'>
                      <div className='limit_container_title'>
                        Limit <br />
                        Recommended
                      </div>
                      <div className='limit_container_text'>LIMIT</div>
                      <div className='limit_container_limit'>
                        {rupeeDecimalFormatter(
                          promoterData?.recommendedLimit,
                          '₹'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='container-box'>
                <div className='row m-10'>
                  <div className='col-md-12 entity_container_heading'>
                    Verified Bank Account Details
                  </div>
                </div>
                <div className='row m-10'>
                  <div className='col-md-9 entity_container_title'>
                    {bankDetails?.accountHolderName || '-'}
                  </div>
                  <div className='col-md-3 button_green'>
                    {bankDetails?.accountType || '-'}
                  </div>
                </div>

                <div className='row m-10 '>
                  <div className='col-md-12 entity_container_value mb-3'>
                    {bankDetails?.accountNumber || '-'}
                    {bankDetails?.isAccNumberVarified === true ? (
                      <img
                        src={Tick}
                        alt='company logo'
                        className='tick_icon'
                      />
                    ) : null}
                  </div>
                </div>

                <div className='row m-10'>
                  <div className='col-md-7'>
                    <div className='entity_container_heading'>
                      IFSC Code: {bankDetails?.ifsc || '-'}
                    </div>
                    <div className='entity_container_value'>
                      {bankDetails?.bankName || '-'}{' '}
                      {bankDetails?.branchName || '-'}
                    </div>
                  </div>
                  <div className='col-md-5 p-0 m-0 mr-n3 d-flex align-items-center modal-wrapper'>
                    <div className='save_button pb-5 '>
                      <button
                        className='save_button py-3 px-3   h6  button-success'
                        onClick={() => this.toggleModal(AddBankDetails)}
                      >
                        Bank Details Update
                      </button>
                    </div>
                    <img
                      className='bank_Details mb-4'
                      src={DetailButton}
                      onClick={() => this.toggleModal(PennyDropResponseModal)}
                    />
                  </div>
                </div>
              </div>

              <div className=' container-box'>
                <div className='row m-10'>
                  <div className='col-md-7 container-box mb-0 '>
                    <img src={Phone} alt='Phone Icon' className='phone_img' />
                    <span className='phone_value'>
                      +91 {deviceDetails?.mobile || '-'}
                    </span>{' '}
                  </div>
                  <div className='col-md-5'>
                    <div className='entity_container_heading'>Device Name</div>
                    <div className='entity_container_value'>
                      {deviceDetails?.deviceName || '-'}
                    </div>
                  </div>
                </div>
                <div className='row m-10'>
                  <div className='col-md-7 container-box mb-0'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <img
                          src={Map}
                          alt='Map Icon'
                          className='map_img'
                          onClick={() => this.toggleModal(LocationModal)}
                        />
                      </div>
                      <div className='col-md-9'>
                        <div className='map_text'>Unit and Image Location</div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-5'>
                    <div className='entity_container_heading'>IMEI No.</div>
                    <div className='entity_container_value'>
                      {deviceDetails?.imeiNumber || '-'}
                    </div>
                  </div>
                </div>
              </div>

              <div className=' container-box container-height'>
                <div className='row m-10'>
                  <div className='col-md-12 entity_container_heading'>
                    Business Photos
                  </div>
                </div>
                <div className='row  ml-4'>
                  {businessDocuments.length !== 0 ? (
                    <div className=' business_images m-10'>
                      <img
                        src={businessDocuments[0]?.fileUrl}
                        alt='image'
                        className='img_left'
                      />
                      <div
                        alt='image'
                        className='img_right'
                        onClick={() => this.toggleModal(ImagesModal)}
                      >
                        <div className='view_more'>
                          +{businessDocuments?.length} <br />
                          click to view all
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='business_images_default'>
                      <div>No Images Uploaded</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='container p-0'>
            <div className=' container-box'>
              <div className='row m-10'>
                <div className='col-md-10 entity_container_heading'>
                  Transaction Details
                </div>
                <a
                  className='col-md-2 entity_container_heading sms_excel'
                  onClick={this.smsExcelDownload}
                >
                  <img src={Excel} alt='image' />
                  <span>Download SMS data</span>
                </a>
              </div>
              <div className='row m-20'>
                <div className='col-md-12 entity_container_title'>
                  Bank Accounts
                </div>
              </div>
              <div className='row m-3 '>
                {accounts?.map((e, index) => {
                  return (
                    <div
                      onClick={() => {
                        this.setState({ activeBank: index });
                      }}
                      className={
                        activeBank === index
                          ? 'col-md-2 bank_account_button bank_account_active'
                          : 'col-md-2 bank_account_button'
                      }
                    >
                      {e?.bank} : {e?.accountNumber}
                    </div>
                  );
                })}
              </div>
              {accounts?.map((e, index) => {
                return (
                  <>
                    {activeBank === index ? (
                      <div className='accounts-container m-3 '>
                        <div className='container-box width'>
                          <div className='row m-10'>
                            <div className='col-md-8 bank_sub_title'>
                              Last 3 months
                            </div>
                          </div>
                          <div className='row m-10'>
                            <div className='col-md-7'>
                              <div className='entity_container_heading'>
                                Total Credit
                              </div>
                              <div className='entity_container_value'>
                                {rupeeDecimalFormatter(
                                  e?.threeMonthCredit,
                                  '₹'
                                )}
                              </div>
                            </div>
                            <div className='col-md-5'>
                              <div className='entity_container_heading'>
                                ABB
                              </div>
                              <div className='entity_container_value'>
                                {rupeeDecimalFormatter(
                                  e?.threeMonthAverageBalance,
                                  '₹'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='container-box width'>
                          <div className='row m-10 '>
                            <div className='col-md-8 bank_sub_title'>
                              Last 6 months
                            </div>
                          </div>
                          <div className='row m-10'>
                            <div className='col-md-7'>
                              <div className='entity_container_heading'>
                                Total Credit
                              </div>
                              <div className='entity_container_value'>
                                {rupeeDecimalFormatter(e?.sixMonthCredit, '₹')}
                              </div>
                            </div>
                            <div className='col-md-5'>
                              <div className='entity_container_heading'>
                                ABB
                              </div>
                              <div className='entity_container_value'>
                                {rupeeDecimalFormatter(
                                  e?.sixMonthAverageBalance,
                                  '₹'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='container-box width'>
                          <div className='row m-10'>
                            <div className='col-md-8 bank_sub_title'>
                              Last 1 year
                            </div>
                          </div>
                          <div className='row m-10'>
                            <div className='col-md-7'>
                              <div className='entity_container_heading'>
                                Total Credit
                              </div>
                              <div className='entity_container_value'>
                                {rupeeDecimalFormatter(e?.oneYearCredit, '₹')}
                              </div>
                            </div>
                            <div className='col-md-5'>
                              <div className='entity_container_heading'>
                                ABB
                              </div>
                              <div className='entity_container_value'>
                                {rupeeDecimalFormatter(
                                  e?.oneYearAverageBalance,
                                  '₹'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </div>

          {/* supplier details card */}

          <div className='entity_container container-box entity_height '>
            <div className='row m-10 mt-4  '>
              <div className='col-md-3 entity_container_heading'>
                Supplier Name
                <div className=' entity_container_title'>
                  {supplierName || '-'}
                  {/* <img className='blue-tik' src={Bluetik}></img> */}
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                OverAll limit
                <div className='text-dark'>
                  {' '}
                  {rupeeDecimalFormatter(supplierOverallLimit, '₹')}
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                Utilised Amount
                <div className='text-dark'>
                  {rupeeDecimalFormatter(utilisedAmt, '₹')}
                </div>
              </div>
              <div className='col-md-3 entity_container_heading'>
                Balance Limit
                <div className='text-dark'>
                  {rupeeDecimalFormatter(supplierAvailableLimit, '₹')}
                </div>
              </div>

              <div className='col-md-3 entity_container_heading mt-5'>
                SupplierId
                <div className='text-dark '>{supplierId || '-'}</div>
              </div>
              <div className='col-md-3 entity_container_heading mt-5'>
                No of Buyers
                <div className='text-dark'>{buyerCount || '-'}</div>
              </div>
              <div className='col-md-3 entity_container_heading mt-5'>
                Over due Amount
                <div className='text-dark'>
                  {rupeeDecimalFormatter(overdueAmt, '₹')}
                </div>
              </div>
            </div>
          </div>

          <div className='container-sub'>
            <div className=' container-box container-sub_min'>
              <div className='row m-10'>
                <div className='col-md-12 entity_container_heading'>
                  Comments
                </div>
              </div>
              <div className='row m-10'>
                <div className='col-md-10 entity_container_title'>
                  Add Credit comments
                </div>
                <div className='col-md-1'>
                  {isEditable === false ? (
                    <div
                      className='edit-comments'
                      onClick={(e) => this.inputHandler(e, 'isEditable')}
                    >
                      <img src={Pen} alt='pen Icon' className='pen_img' />
                    </div>
                  ) : (
                    <div>
                      <span
                        className='save-comments'
                        onClick={(e) => this.saveCommentsHandler(e, 'comment')}
                      >
                        save
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row m-20'>
                <div className='col-md-12'>
                  <div
                    className='comments_text-area'
                    textareaplaceholder='  Write here... '
                    cols='30'
                    rows='6'
                    id='comment'
                    tabIndex='0'
                    name='comment'
                    contentEditable={isEditable}
                    onInput={(e) =>
                      this.setState({
                        comment: e.currentTarget.textContent,
                      })
                    }
                  >
                    {comment}
                  </div>
                </div>
              </div>
            </div>

            <div className=' container-box container-sub_min'>
              <div className='row m-10'>
                <div className='col-md-12 entity_container_heading'>
                  Comments
                </div>
              </div>
              <div className='row m-10'>
                <div className='col-md-10 entity_container_title'>
                  Add Conditions
                </div>
                <div className='col-md-1'>
                  {isConditionEditable === false ? (
                    <div
                      className='edit-comments'
                      onClick={(e) =>
                        this.inputHandler(e, 'isConditionEditable')
                      }
                    >
                      <img src={Pen} alt='pen Icon' className='pen_img' />
                    </div>
                  ) : (
                    <div>
                      <span
                        className='save-comments'
                        onClick={(e) =>
                          this.saveCommentsHandler(e, 'condition')
                        }
                      >
                        save
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row m-20'>
                <div className='col-md-12'>
                  <div
                    className='comments_text-area'
                    textareaplaceholder='  Write here... '
                    cols='30'
                    rows='6'
                    id='condition'
                    tabIndex='0'
                    name='condition'
                    contentEditable={isConditionEditable}
                    onInput={(e) =>
                      this.setState({
                        condition: e.currentTarget.textContent,
                      })
                    }
                  >
                    {condition}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-box'>
            <div className='row m-10'>
              <div className='col-md-12 entity_container_heading '>
                Decision
              </div>
            </div>
            <div className='row m-10'>
              <div className='col-md-12 entity_container_title'>
                Final Decision
              </div>
            </div>
            <div className='row m-10 '>
              <div className='col-md-4 input_button pr-0 '>
                <div className=' text-black'>
                  <div>
                    Enter value less than or equal to the approved credit limit
                  </div>
                  <input
                    required={true}
                    type='number'
                    // defaultValue={promoterData?.recommendedLimit}
                    placeholder='Enter the Approval amount'
                    inputValue={promoterData?.recommendedLimit}
                    name='offerLimitAmt'
                    onChange={this.programHandler}
                  />
                </div>
              </div>

              <div className='col-md-3 p-0'>
                <div className=' text-black'>
                  <div> Program</div>
                  <select
                    onChange={this.programHandler}
                    name='programName'
                    required
                    value={this.state.programName}
                  >
                    <option disabled selected>
                      Select Program
                    </option>
                    {programList.map((e, index) => {
                      return <option key={`program-${index}`}> {e} </option>;
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-3 p-0'>
                <div className=' text-black'>
                  <div>Sub-Program </div>
                  <select
                    onChange={this.programHandler}
                    name='subProgramName'
                    required
                    disabled={!this.state.programName}
                    value={this.state.subProgramName}
                  >
                    <option disabled selected>
                      Select Sub-Program
                    </option>
                    {subProgramList.map((e, index) => {
                      return (
                        <option key={`sub-program-${index}`}> {e} </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-1 promoters_image '>
                <button
                  onClick={() => this.toggleModal(SubProgramModal)}
                  className='promoters_image_documents  promoters_image_details'
                  disabled={
                    !this.state.programName || !this.state.subProgramName
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className='decision_buttons m-10'>
              <div
                className=' active_button'
                onClick={this.approveLimitHandler}
              >
                Approve and Create Offer
              </div>

              <div
                className='reject_button'
                onClick={() => this.toggleModal(RejectCases)}
              >
                <img src={Reject} alt='Reject Icon' className='icon_img' />
                <div className='reject_image'>
                  <span>Reject</span>
                </div>
              </div>
            </div>
          </div>

          <CredoModal
            isOpen={modalOpen}
            styles={
              modalType === AddBankDetails
                ? {
                    content: {
                      width: '50%',
                      height: 'max-content',
                    },
                  }
                : modalType === PennyDropResponseModal
                ? {
                    content: {
                      width: '40%',
                      height: 'max-content',
                    },
                  }
                : modalType === RejectCases
                ? {
                    content: {
                      width: '40%',
                      height: '40%',
                    },
                  }
                : modalType === KycModal
                ? {
                    content: {
                      minWidth: '70vw',
                      height: 'auto',
                    },
                  }
                : modalType === UserProfileModal
                ? {
                    content: {
                      maxWidth: '40vw',
                      display: 'table',
                    },
                  }
                : modalType === ImagesModal || LocationModal
                ? {
                    content: {
                      minWidth: '65vw',
                      minHeight: '85vh',
                    },
                  }
                : {
                    content: {
                      minWidth: '50vw',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            stateData={this.state}
            propsData={this.props}
            rejectHandler={this.rejectHandler}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ApplicationReducer }) => ({
  promoterData: ApplicationReducer.promoterData,
  address: ApplicationReducer.address,
  bankDetails: ApplicationReducer.bankDetails,
  compareDetails: ApplicationReducer.compareDetails,
  deviceDetails: ApplicationReducer.deviceDetails,
  comment: ApplicationReducer.comment,
  condition: ApplicationReducer.condition,
  photos: ApplicationReducer.photos,
  offerLimitAmt: ApplicationReducer.offerLimitAmt,
  programs: ApplicationReducer.programs,
  transactionDetails: ApplicationReducer.transactionDetails,
  dedupeDetails: ApplicationReducer.dedupeDetails,
  programList: ApplicationReducer.programList,
  subProgramList: ApplicationReducer.subProgramList,
  digiLocker: ApplicationReducer.digiLocker,
  cpoDocument: ApplicationReducer.cpoDocument,
  supplierDetails: ApplicationReducer.supplierDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSupplierDetails,
      getCpoDocument,
      getDigilockerDetails,
      getBusinessDetails,
      getAddressDetails,
      getCompareDetails,
      getConditionNotes,
      getCommentNotes,
      getBankDetails,
      getPhotos,
      getActiveLoan,
      loaderModal,
      getSubProgramList,
      getProgramList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverView);
