import React, { Component } from 'react';
import ReactTable from 'react-table';
import {
  API_ADMIN_SOA_PDF,
  API_GET_SOA_INVOICE_DETAILS,
} from '../../../utils/APIUrls';
import { GET } from '../../../utils/webAPI.service';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { loaderModal } from '../../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../../../components/Button';
import ExcelIcon from '../../../assets/images/excel.svg';
import PdfIcon from '../../../assets/images/pdf.svg';
import InvoiceButton from '../../../assets/images/invoice-button.png';
import axios from 'axios';
import FileDownload from 'js-file-download';

class InvoiceInterestDetails extends Component {
  state = {
    invoiceInterestData: {},
    showDatePicker: false,
    activeFigure: 'Soa',
  };
  componentDidMount() {
    const { rowData = {}, actions, paymentModalData = {} } = this.props || {};
    let invoiceId;
    //The data comes from "Interest and charges." Then, the InvoiceId is destructured from rowData. When the data comes from "Disbursed Invoice," it is destructured from paymentModalData.
    if (Object.keys(rowData).length > 0) {
      ({ invoiceId } = rowData);
    } else {
      ({ invoiceId } = paymentModalData);
    }
    actions.loaderModal(true);
    GET(API_GET_SOA_INVOICE_DETAILS(invoiceId))
      .then(({ data }) => {
        if (data.code === 200) {
          this.setState({ invoiceInterestData: data?.data });
        } else {
          alert(data?.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  }

  invoiceHandler = async (type) => {
    this.setState({
      activeFigure: type,
    });
  };

  getDateFilterData = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
    }));
  };

  soaInvoiceExcelDownload = async (invoiceID) => {
    //single Invoice soa excel download
    let xUserId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': xUserId,
    };

    try {
      const response = await axios({
        url: `${API_ADMIN_SOA_PDF}`,
        method: 'POST',
        headers: headers,
        responseType: 'blob', // Important
        data: {
          format: 'ADMIN_USER_EXCEL',
          identifier: invoiceID,
        },
      });
      FileDownload(response.data, `soa_invoice_excel_${invoiceID}.xlsx`);
    } catch (error) {
      alert('Something went wrong');
      throw error;
    }
  };

  render() {
    const AccruedColumns = [
      {
        Header: () => (
          <>
            <div className='ml-4'>Date</div>
          </>
        ),
        accessor: 'date',
        width: 150,
      },
      {
        Header: 'Principal Outstanding',
        accessor: 'principalOutstanding',
        Cell: (props) => {
          return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
        },
      },
      {
        Header: 'Interest Rate %',
        accessor: 'interestRate',
        Cell: (props) => {
          return <div className='ml-2'>{`${props?.value} %` || '-'}</div>;
        },
      },
      {
        Header: 'Daily Interest',
        accessor: 'accruedInterest',
        Cell: (props) => {
          return (
            <div className='ml-2'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Cummulative Daily Interest',
        accessor: 'cumulativeAccruedInterest',
        Cell: (props) => {
          return (
            <div className='ml-4'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
    ];
    const Soa = [
      {
        Header: () => (
          <>
            <div className='pl-2'>Transaction Date </div>
          </>
        ),
        accessor: 'transactionDate',
        width: 130,
      },
      {
        Header: () => (
          <>
            <div className='ml-4'>Effective Date</div>
          </>
        ),
        accessor: 'effectiveDate',
        width: 120,
      },
      {
        Header: () => (
          <>
            <div className='ml-3'>Description</div>
          </>
        ),
        accessor: 'description',
      },
      {
        Header: () => (
          <>
            <div className='ml-3'>Due Date</div>
          </>
        ),
        accessor: 'dueDate',
        width: 130,
      },
      {
        Header: 'Debit',
        accessor: 'debit',
        Cell: (props) => {
          return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
        },
      },
      {
        Header: 'Credit',
        accessor: 'credit',
        Cell: (props) => {
          return <div>{rupeeDecimalFormatter(props?.value, '₹')}</div>;
        },
      },
      {
        Header: () => (
          <>
            <div>Re-Payment Id</div>
          </>
        ),
        accessor: 'repaymentId',
        Cell: (props) => {
          return (
            <div className='ml-2'>{props?.value ? props?.value : '-'}</div>
          );
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-4'>Outstanding Amount</div>
          </>
        ),
        accessor: 'outstandingAmount',
        Cell: (props) => {
          return (
            <div className='pl-5'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
    ];

    let { invoiceInterestData } = this.state || {};
    let { accruedInterestDataList, interestPerDay } = invoiceInterestData || [];
    const { rowData = {}, actions, paymentModalData = {} } = this.props || {};
    let invoiceId;
    if (Object.keys(rowData).length > 0) {
      ({ invoiceId } = rowData);
    } else {
      ({ invoiceId } = paymentModalData);
    }

    return (
      <div className='modal-wrapper manage_user-wrapper'>
        <h3 className='card_heading'>
          {' '}
          SOA For Invoice No: {invoiceId}
          <span className='ml-5'>
            <img
              src={ExcelIcon}
              onClick={() => this.soaInvoiceExcelDownload(invoiceId)}
              alt='icon'
              className='icon'
            />

            <img src={PdfIcon} alt='icon' className='icon ml-4' />
          </span>
        </h3>
        <div className='row'>
          <div className='col-12'>
            <div className='card-box'>
              <div className='row'>
                <div className='col-md-3 text-grey'>
                  User ID
                  <div className='text-bold'>
                    {invoiceInterestData?.userId || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Supplier Name
                  <div className='text-bold'>
                    {invoiceInterestData?.supplierName || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Transaction Date
                  <div className='text-bold'>
                    {invoiceInterestData?.transactionDate || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Status
                  <div className='text-bold'>
                    {invoiceInterestData?.status || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Invoice Amount
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.invoiceAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Margin Amount
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.marginAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Extension Interest Rate (%)
                  <div className='text-bold'>
                    {invoiceInterestData?.extensionInterestRate || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Extension Tenure
                  <div className='text-bold'>
                    {invoiceInterestData?.extensionTenure || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Subvention Amount
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.subventionAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Payment Amount
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.principalAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Interest Rate (%)
                  <div className='text-bold'>
                    {invoiceInterestData?.interestRate || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Standard Tenure
                  <div className='text-bold'>
                    {invoiceInterestData?.standardTenure || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Subvention Interest Rate (%)
                  <div className='text-bold'>
                    {invoiceInterestData?.subventionInterestRate || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Subvention Tenure
                  <div className='text-bold'>
                    {invoiceInterestData?.subventionTenure || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Overdue Interest Rate(%)
                  <div className='text-bold'>
                    {invoiceInterestData?.overdueInterestRate || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Grace Period Tenure
                  <div className='text-bold'>
                    {invoiceInterestData?.gracePeriod || '-'}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Accrued Interest
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.accruedInterest,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Due Interest (Posted)
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.dueInterest,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Overdue Interest
                  <div className='text-bold'>
                    {' '}
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.overdueInterest,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Principal (Outstanding)
                  <div className='text-bold'>
                    {' '}
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.principalOutstanding,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-3 text-grey'>
                  Gross Outstanding
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.grossOutstanding,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-5 text-grey'>
                  Total Outstanding (Without Accrued Entries)
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      invoiceInterestData?.totalOutStanding,
                      '₹'
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='row generic_button_box '>
              <div
                onClick={() => this.invoiceHandler('Soa')}
                className={
                  this.state.activeFigure === 'Soa'
                    ? 'col-md-1 generic_button_active'
                    : 'col-md-1 generic_button'
                }
              >
                Soa
              </div>
              <div
                onClick={() => this.invoiceHandler('Accrued')}
                className={
                  this.state.activeFigure === 'Accrued'
                    ? 'col-md-2 generic_button_active'
                    : 'col-md-2 generic_button'
                }
              >
                Daily Calculated Interest
              </div>
            </div>
          </div>
        </div>

        <div className='card m-t-0 interest_charges_wrapper'>
          <div className='card_heading_table row'>
            <h4 className=' col-md-10  mt-4'>
              {this.state.activeFigure === 'Accrued' && 'Per Day Interest'}{' '}
            </h4>
            <h5 className='col-md-2  card_heading_secondary_right'>
              {/* <img
                src={InvoiceButton}
                alt='icon'
                className='icon_image '
                // onClick={() =>
                //   this.setState((prevState) => ({
                //     ...prevState,
                //     showDatePicker: !prevState.showDatePicker,
                //   }))
                // } //will implement this filter in future
              /> */}
            </h5>
          </div>

          {this.state.showDatePicker ? (
            <div className='row d-flex justify-content-end'>
              <div className='card date-picker col-4'>
                <div className='row  '>
                  <div className='col text-grey mt-2'>
                    Start Date
                    <input
                      className='date'
                      type='date'
                      onChange={(e) =>
                        this.setState({
                          startDate: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                  <div className='col text-grey mt-2'>
                    End Date
                    <input
                      className='date'
                      type='date'
                      onChange={(e) =>
                        this.setState({
                          endDate: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </div>
                <div className='row mt-3 ml-2'>
                  <Button onClick={() => this.getDateFilterData()}>OK</Button>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.activeFigure === 'Soa' ? (
            <ReactTable
              columns={Soa}
              data={interestPerDay}
              minRows={1}
              pageSize={interestPerDay?.length}
              showPagination={false}
            />
          ) : this.state.activeFigure === 'Accrued' ? (
            <ReactTable
              columns={AccruedColumns}
              data={accruedInterestDataList || []}
              minRows={1}
              pageSize={accruedInterestDataList?.length}
              showPagination={false}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ loaderModal }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceInterestDetails);
