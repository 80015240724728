import React, { Component } from 'react';
import ReactTable from 'react-table';
import { getInvitationList } from './invite.action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Processing from '../../../../assets/images/Processing.svg';
import Greentick from '../../../../assets/images/green tick.svg';
class Stagged extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFigure: 'Outstanding',
      pageNumber: 1,
      searchBy: 'buyerStatus',
      searchValue: 'all',
    };
  }

  getPageData = async (type, totalPages) => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1; // Update page number for next page
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1; // Update page number for previous page
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getInvitationList(updatedPageNumber, searchBy, searchValue, anchorId);
  };

  invitationTabHandler = async (type, value) => {
    await this.setState({
      activeFigure: type,
      searchValue: value,
    });
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    getInvitationList(pageNumber, searchBy, searchValue, anchorId);
  };

  componentDidMount = () => {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { pageNumber, searchBy, searchValue } = this.state;
    const { getInvitationList } = actions;
    getInvitationList(pageNumber, searchBy, searchValue, anchorId);
  };

  render() {
    let { match, history, actions } = this.props,
      { anchorId } = match.params;
    let { buyerInvitation } = this.props || {};
    let { buyersList = [], totalPages } = buyerInvitation || {};
    let { activeFigure, pageNumber } = this.state;
    const BUYER_STATUS_PARAMS = {
      PENDING: 'onboarding_pending',
      COMPLETED: 'onboarding_completed',
    };
    const buyerInvitationColumn = [
      {
        Header: 'PARTY NAME',
        accessor: 'buyerName',
        width: 390,
        Cell: (props) => {
          let { original } = props;
          let { buyerName } = original;
          return <>{<div>{buyerName || '-'}</div>}</>;
        },
      },
      {
        Header: 'Mobile No',
        accessor: 'mobileNumber',
      },

      {
        Header: 'Gstin',
        accessor: 'buyerGstIn',
        Cell: (props) => {
          let { original } = props;
          let { buyerGstIn } = original;
          return <>{buyerGstIn || '-'}</>;
        },
      },
      {
        Header: 'Status',
        accessor: 'invitationStatus',
        Cell: (props) => {
          let { original } = props;
          let { invitationDate, buyerStatus } = original;
          return (
            <>
              <div>
                {buyerStatus === BUYER_STATUS_PARAMS?.PENDING ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Processing} />
                    <div className='ml-3'>Onboarding Pending</div>
                  </div>
                ) : buyerStatus === BUYER_STATUS_PARAMS?.COMPLETED ? (
                  <div className='d-flex'>
                    <img className='img-icon' src={Greentick} />
                    <div className='ml-3'>Onboarded</div>
                  </div>
                ) : null}
              </div>
              <div className='mt-2'>{invitationDate || '-'}</div>
            </>
          );
        },
      },
    ];
    return (
      <>
        <div className='container invoices-wrapper mt-5'>
          <div className='row generic_button_box my-4'>
            <div
              onClick={() => this.invitationTabHandler('Outstanding', 'all')}
              className={
                activeFigure === 'Outstanding'
                  ? 'col-md-1 generic_button_active'
                  : 'col-md-1 generic_button'
              }
            >
              All
            </div>
            <div
              onClick={() =>
                this.invitationTabHandler('Onboarded', 'onboarding_completed')
              }
              className={
                activeFigure === 'Onboarded'
                  ? 'col-md-1 generic_button_active'
                  : 'col-md-1 generic_button'
              }
            >
              Onboarded
            </div>
            <div
              onClick={() =>
                this.invitationTabHandler('Onboarding', 'onboarding_pending')
              }
              className={
                activeFigure === 'Onboarding'
                  ? 'col-md-2 generic_button_active'
                  : 'col-md-2 generic_button'
              }
            >
              Onboarding Pending
            </div>
          </div>
          <div className='mt-1'>
            <ReactTable
              data={buyersList || []}
              columns={buyerInvitationColumn}
              defaultPageSize={10}
              showPagination={false}
              sortable={false}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  disabled={!(pageNumber > 1)}
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev', totalPages)}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {totalPages === 0 ? 0 : pageNumber} of {totalPages}
                </span>
              </li>

              <li>
                <button
                  disabled={!(totalPages > pageNumber)}
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next', totalPages)}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ invitationReducer }) => ({
  buyerInvitation: invitationReducer?.buyerInvitation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvitationList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stagged);
